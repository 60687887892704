(function () {
    'use strict';

    angular.module('adminApp')
        .run(adminAppRoutesToStyles);

    adminAppRoutesToStyles.$inject = ['PageService', 'routesConstant'];

    function adminAppRoutesToStyles(PageService, routesConstant) {

        [
            routesConstant.ADMIN.DASHBOARD.stateName,
            routesConstant.ADMIN.ORGANIZATIONS.stateName,
            routesConstant.ADMIN.USERS.stateName
        ]
        .forEach(function (stateName) {
            PageService.addAflPageViewToStateMap(stateName, PageService.AFLPAGEVIEW.ADMIN);
        });

    }
})();
